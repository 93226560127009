<template>
  <v-container fluid>
    <v-row>
      <v-subheader
        >Minha Empresa > Sociedade > {{ partner.fullName }}</v-subheader
      >
      <v-card width="100%">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>
        <k-ask-change-btn></k-ask-change-btn>

        <partner-data v-if="partner" :partner="partner"></partner-data>

        <v-row class="py-2">
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <v-btn
              v-on:click="clickUpdateTel()"
              color="green"
              class="white--text"
            >
              SALVAR NOVO TELEFONE
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import partnerData from '@src/components/company/partner-data.vue'

import kAskChangeBtn from '@src/components/widget/AskChangeBtn.vue'

export default {
  name: 'Expense',
  components: {
    partnerData,
    kAskChangeBtn,
  },
  computed: {
    ...mapState({
      partner: (state) => state.partner.partner,
      alerts: (state) => state.partner.alerts,
    }),
  },
  data: () => ({
    headerTitle: 'Sociedade',
    headerDescription: `Estes são os dados referentes ao(s) sócio(s) da sua empresa.
    Nessa área, você poderá alterar somente o telefone para contato.
    Para alterar qualquer outra informação, será necessário clicar em
    "solicitar mudança" e contratar um serviço adicional. Lembre-se
    que essas alterações também precisarão ser efetuadas no contrato
    da sua empresa e registradas nos organismos oficiais.`,
  }),
  methods: {
    ...mapActions({
      getPartner: 'partner/fetchPartner',
      updateTel: 'partner/savePartnerField',
    }),
    clickUpdateTel: function() {
      this.updateTel({
        id: this.partner.id,
        phone: this.removeMask(this.partner.phone),
      }).then(() => {
        this.$store.commit('snackbarFlashMessage/setSuccessRegister')
        this.$vuetify.goTo(0, {
          duration: 300,
          offset: 0,
          easing: 'linear',
        })
      })
    },
    removeMask(value) {
      return value.replace(/\D/g, '')
    },
  },
  mounted() {
    this.getPartner(this.$route.params.id)
  },
  props: {
    id: {
      required: true,
    },
  },
}
</script>
<style scoped></style>
