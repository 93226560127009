<template>
  <div class="px-6 py-2">
    <v-simple-table dense>
      <template v-slot:default class="pa-5">
        <thead>
          <tr>
            <th class="text-left">Denominação</th>
            <th class="text-left">Dados</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(field, index) in fields">
            <td class="grey--text">{{ field }}</td>
            <td>
              <span v-if="index !== 'phone'">
                {{ formatPartner[index] }}
              </span>
              <span v-if="index === 'phone'">
                <template v-if="!snackbar">
                  {{ formatPartner[index] }}
                  <v-btn @click.native="snackbar = !snackbar" icon>
                    <v-icon v-if="!snackbar" color="grey darken-2" medium
                      >edit</v-icon
                    >
                  </v-btn>
                </template>
                <v-row v-else no-gutters>
                  <v-col cols="4">
                    <v-text-field
                      v-model="newTel"
                      v-mask="['(##) #####-####', '(##) ####-####']"
                      label="Novo telefone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="mt-5">
                    <v-btn @click.native="snackbar = !snackbar" icon>
                      <v-icon color="grey darken-2" medium>close</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="snackbar"
                      @click.native="confirmChangeTel()"
                      icon
                    >
                      <v-icon color="grey darken-2" medium>check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </span>
            </td>
            <v-divider
              v-if="index !== fields[Object.keys(fields).pop()]"
              :key="`${index}-div`"
            ></v-divider>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  props: {
    partner: {
      type: Object,
    },
  },
  data: () => ({
    snackbar: false,
    newTel: null,
    fields: {
      fullName: 'Nome Completo',
      email: 'Email Primário',
      dateBirth: 'Data de Nascimento',
      countyBirth: 'Municipio do Nascimento',
      stateBirth: 'Estado do Nascimento',
      gender: 'Sexo',
      fatherName: 'Nome do Pai',
      motherName: 'Nome da Mãe',
      rg: 'RG',
      orgaoEmissorRg: 'Orgão Emissor',
      dataExpedicaoRg: 'Data da Expedição',
      cpf: 'CPF',
      tituloEleitor: 'Titulo de Eleitor',
      civilStatus: 'Estado Civil',
      matrimonialRegime: 'Regime de Casamento',
      occupation: 'Profissão/ Formação',
      incomeTaxDeclaration: 'Recibo do Último IRPF',
      incomeTaxDeclarationYear: 'Ano da Declaração',
      nationality: 'Nacionalidade',
      cep: 'CEP Residencial',
      address: 'Endereço Residencial',
      addressNumber: 'Nº',
      addressComplement: 'Complemento',
      district: 'Bairro',
      city: 'Cidade', // falta
      state: 'Estado', // falta
      phone: 'Telefone Fixo',
    },
    dataPartner: {
      fullName: '',
      email: '',
      dateBirth: '',
      countyBirth: '',
      stateBirth: '',
      gender: '',
      fatherName: '',
      motherName: '',
      rg: '',
      orgaoEmissorRg: '',
      dataExpedicaoRg: '',
      cpf: '',
      tituloEleitor: '',
      civilStatus: '',
      matrimonialRegime: '',
      occupation: '',
      incomeTaxDeclaration: '',
      incomeTaxDeclarationYear: '',
      nationality: '',
      cep: '',
      address: '',
      addressNumber: '',
      addressComplement: '',
      district: '',
      city: '', // falta
      state: '', // falta
      phone: '',
    },
  }),
  methods: {
    confirmChangeTel() {
      this.partner.phone = this.newTel
      this.snackbar = false
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split(' ')[0].split('-')
      return `${day}/${month}/${year}`
    },
  },
  computed: {
    formatPartner() {
      if (this.partner.id) {
        this.dataPartner = Object.assign(this.partner)
        this.dataPartner.rg = this.$options.filters.toRG(this.dataPartner.rg)
        this.dataPartner.cpf = this.$options.filters.toCPF(this.dataPartner.cpf)
        this.dataPartner.cep = this.$options.filters.toCEP(this.dataPartner.cep)
        this.dataPartner.tituloEleitor = this.$options.filters.toTituloEleitor(
          this.dataPartner.tituloEleitor
        )
        this.dataPartner.incomeTaxDeclaration = this.$options.filters.toReciboIRPF(
          this.dataPartner.incomeTaxDeclaration
        )
        this.dataPartner.dateBirth = this.formatDate(this.dataPartner.dateBirth)
        this.dataPartner.dataExpedicaoRg = this.formatDate(
          this.dataPartner.dataExpedicaoRg
        )
        this.dataPartner.phone = this.$options.filters.toTEL(
          this.dataPartner.phone
        )
        if (!this.dataPartner.matrimonialRegime) {
          delete this.fields.matrimonialRegime
        }
      }
      return this.dataPartner
    },
  },
}
</script>
<style scoped>
.field .name {
  color: gray;
}
</style>
